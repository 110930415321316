@import '../../theme.scss';

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    align-items: center;
}

.marginCard {
    margin-top: 70px;
}

.carLabelContainer {
    height: 5vw;
    flex-grow: 0;
    display: flex;
    align-items: center;
    width: 100%;
}

.carLabel {
    color: $text-bright;
    font-size: 1.2rem;
    text-align: center;
    padding: 5px 8px;
    border-radius: 10px;
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.image {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

$cardWith: 30vw;
.commonContainer {
    box-sizing: border-box;
    height: calc($cardWith * 0.6);;
    width: $cardWith;
    background-color: $dark-transparent;
    margin: 15px 0px;
    display: flex;
    flex-direction: column;
}

.imageContainer {
    object-position: center;
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.specContainer {
    object-position: center;
    display: flex;
    justify-content: center;
    position: relative;
    // Spec is wrapped into a <a> element
    text-decoration: none;
    pointer-events: none;
}

.emptyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.carLabelEmpty {
    display: flex;
    justify-content: center;
    width: 100%;
}

.carButtons {
    display: flex;
}

.iconButton {
    z-index: 1;
    padding: 3px 5px 5px 5px;
    background-color: $dark-opaque;
    width: 1.5em;
    height: 1.5em;
}

.commonContainer:hover .iconButton {
    background-color: $dark-transparent;
    cursor: pointer;
}

.switchButtons {
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-right: 15px;
}

.carCaption {
    color: $text-bright;
    background-color: $dark-transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: normal;
    padding: 0px 20px 0px 20px;
}

.carYear {
    text-align: right;
    font-weight: bold;
}

.carTitle {
    flex: 1;
    height: 100%;
    min-width: 0; // to allow element to shrink when page is smaller
}

.carLongLabel {
    /* Long labels */
    max-width: 100%;
    /* do not reduce summary arrow */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
}


@media (orientation: portrait) {
    .card {
        max-width: 100%;
        width: 100%;
        max-height: none;
        height: 40vh;
        margin: 0;
    }

    .marginCard {
        margin-top: 30px;
    }

    .commonContainer {
        height: 100%;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 7vw;
    }

    .iconButton {
        height: 9vw;
        width: 9vw;
        opacity: 1;
    }

    .commonContainer .iconButton {
        opacity: 1;
        background-color: $dark-transparent;
        cursor: pointer;
    }
}