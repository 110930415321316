@import '../../theme.scss';

.titleContainer {
    width: auto;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 20px 0px;
    width: 30vw;
    max-width: 1000px;
    border-radius: 10px;
}

.title {
    font-size: 2rem;
    font-weight: bold;
}


.chromeText {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: sans-serif;
    background-clip: text;
    color: transparent;
}

.subTitle {
    color: $text-bright;
    padding: 0px 10px;
    margin: 0px;
}


@media (orientation: portrait) {
    .title {
        font-size: 1.3em;
    }
    .titleContainer {
        width: 95%;
        max-width: 95%;
        margin: 60px 0px 0px 0px;
        padding: 5px;
    }
}
