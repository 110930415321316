@import '../../theme.scss';

.imageLink {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-width: 3px;
  border-color: $text-medium;
  border-style: solid;
  box-sizing: border-box;
  width: 100%;
  height: 240px;
  background-color: white;
}

.name {
  /* Long labels */
  max-width: 70%; /* do not reduce summary arrow */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.specContainer {
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.specContainer legend {
  color: $text-medium;
}

.specContainer span {
  color: $text-extra-bright;
}

.link {
  text-decoration: underline;
  pointer-events: initial;
  color: $text-medium;
}

.bar {
  width: 0%; /* default value; will be computed later */
  height: 3px;
  margin: 5px 0 10px 0;
}

.barWeight {
  background-color: orange;
}

.barPower {
  background-color: yellow;
}

.barRatio {
  background-color: red;
}

.barPrice {
  background-color: lightgreen;
}

.barTitle {
  display: flex;
  justify-content: space-between;
  font-size: small;
}
