@import '../theme.scss';

.mainSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0px 20px 20px 20px;
    margin-top: -20px;
    box-sizing: border-box;
}

.carsContainer {
    align-self: center;
    align-items: flex-start;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 10vh 0 0 0;
}


@media (orientation: portrait) {
    
    .carsContainer {
        display: block;
        margin: -20px 0 0 0;
    }
}

